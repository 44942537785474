<template>
  <div class="partner-create">
    <el-form :model="form" label-position="top" :rules="rules" ref="form">
      <el-row :gutter="20">
        <el-col :span="17">
          <el-card>
            <el-form-item label="合作伙伴名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入合作伙伴名称"></el-input>
            </el-form-item>
            <el-form-item label="简介" prop="content">
              <ckeditor :editor="editor" v-model="form.content" :config="editorConfig"></ckeditor>
            </el-form-item>
          </el-card>
        </el-col>
        <el-col :span="7">
          <el-card>
            <el-form-item label="logo" prop="image">
              <div style="text-align: center">
                <el-upload
                  class="upload-demo"
                  drag
                  :accept="accept"
                  :before-upload="beforeUpload"
                  :on-success="successUpload"
                  :action="UPLOAD_PARTNER_IMAGES">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">
                    只能上传{{ accept }}文件，且不超过{{ size }}MB
                    <p v-if="form.image">
                      <el-link type="success" :href="imageUrl" target="_blank">已上传,点击查看</el-link>
                    </p>
                  </div>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label="状态" prop="state">
              <el-radio-group v-model="form.state" size="mini">
                <el-radio :label="0">未发布</el-radio>
                <el-radio :label="1">已发布</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit" :loading="submitting">确定保存</el-button>
            </el-form-item>
          </el-card>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<style lang="scss">
.el-upload {
  display: block;
  .el-upload-dragger {
    width: 100%;
  }
}
.el-form-item .el-form-item__content {
  line-height: 1;
}
.ck-editor__editable { min-height: 800px; }
</style>
<script>
import editor from '@ckeditor/ckeditor5-build-classic'
import { partnerEdit, partnerInfo, UPLOAD_PARTNER_IMAGES } from '@/request/modules/partner'
import { getSuffix } from '@/libs/functions'
import { EDITOR_CONFIG } from '@/config/ckeditor'
export default {
  data () {
    return {
      UPLOAD_PARTNER_IMAGES,
      size: 2, // MB 可用的大小
      ext: ['jpg', 'jpeg', 'png'],
      editor,
      editorConfig: EDITOR_CONFIG,
      form: {
        id: this.$route.query.id,
        name: '',
        content: '',
        image: '',
        state: 0
      },
      rules: {
        name: [
          { required: true, message: '请填写合作伙伴名称', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请填写简介', trigger: 'blur' }
        ],
        image: [
          { required: true, message: '请选择封面图片', trigger: 'change' }
        ],
        state: [
          { required: true, message: '请选择发布状态', trigger: 'change' }
        ]
      },
      submitting: false
    }
  },
  computed: {
    accept () {
      return this.ext.map(item => '.' + item).join(',')
    },
    imageUrl () {
      return process.env.VUE_APP_URL + '/storage/' + this.form.image
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.loading = true
      partnerInfo({ id: this.form.id }).then(resp => {
        console.log(resp)
        Object.keys(this.form).map(key => {
          this.form[key] = resp[key]
        })
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    beforeUpload (file) {
      console.log(file)
      if (file.size > this.size * 1024 * 1024) {
        this.$message.error(`文件大小应小于${this.size}MB`)
        return false
      }
      const ext = getSuffix(file.name)
      if (!this.ext.includes(ext)) {
        this.$message.error(`文件后缀应为${this.accept}`)
        return false
      }
      return true
    },
    successUpload (resp, file) {
      console.log(resp)
      if (resp.code === 200) {
        this.form.image = resp.content.path
      } else {
        this.$message.error(resp.message)
      }
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitting = true
          partnerEdit(this.form).then(resp => {
            this.$message.success('保存成功!')
            this.submitting = false
            this.$router.push('/partner')
          }).catch(() => {
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
