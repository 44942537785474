import request from '@/request'
import { BASE_URL } from '@/config/app'

export const UPLOAD_PARTNER_IMAGES = BASE_URL + '/partner/upload'

/**
 * 合作伙伴列表
 * @returns {Promise<AxiosResponse<any>>}
 */
export const partnerList = (params) => {
  return request.get('/partner', {
    params
  })
}

/**
 * 合作伙伴详情
 * @returns {Promise<AxiosResponse<any>>}
 */
export const partnerInfo = (params) => {
  return request.get('/partner/info', {
    params
  })
}

/**
 * 合作伙伴创建
 * @returns {Promise<AxiosResponse<any>>}
 */
export const partnerCreate = (data) => {
  return request.post('/partner/create', data)
}

/**
 * 合作伙伴编辑
 * @returns {Promise<AxiosResponse<any>>}
 */
export const partnerEdit = (data) => {
  return request.post('/partner/edit', data)
}

/**
 * 合作伙伴删除
 * @returns {Promise<AxiosResponse<any>>}
 */
export const partnerDelete = (data) => {
  return request.post('/partner/delete', data)
}
